//import { Link } from "react-router-dom";
import React, { Component } from 'react';
import secureStorage from '../../config/encrypt';
import { connect } from 'react-redux';
import { DealerService } from '../../service';
import { withTranslation } from 'react-i18next';
import MasterService from './../../service/MasterService';
import DatePicker from "react-datepicker";
import {CONF_VAL} from '../../config/constant';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

class Report extends Component {
  constructor(props) {
    super(props);
    // var today = new Date();//deleteme
    
    this.state = {
      redirect: false,
      dashboardcardinfo: [],
      dashboardleadcard: [],
      formData: { lead_report: "today" },
      dashboardReport:[],
      role :"admin",
      user_id : "",
      loading:false,
      filterData: { }
    };
  }
  updateReduxData = async () => {
    if (secureStorage.getItem('loginUserInfo')) {
      var loginResponse = secureStorage.getItem('loginUserInfo');
      this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
      this.afterLoginSetState(loginResponse)
    } else {
      this.setState({ redirect: '/login' })
    }
  }
  afterLoginSetState = (loginResponse) => {
    //console.log(loginResponse.data.user_data);
    let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.user_type || null;
      this.setState({ role:role,user_id:loginResponse.data.user_data.id })
  }
  componentDidMount = async () => {
    await this.updateReduxData();
    await this.getUserReport();
    //await this.getdashboardcard();
    //await this.getdashboardleadcard(this.state.formData && this.state.formData.lead_report);
  }
  componentWillReceiveProps = async(nextProps) => {
    if(nextProps.history.location.state == 'freshQcReport'){
      await this.updateReduxData();
      await this.getUserReport();
    }
  }
  getUserReport =async() =>{
        this.setState({loading:true});
         // get dashboard report
         let postObjList = {filterData: {...this.state.filterData},type:2};
         if(this.state.role=='user'){
          postObjList.qc_user_id= this.state.user_id  ;
         }         
         //if(postObjList.filterData.created_to) postObjList.filterData.created_to.setHours(23, 59, 59);
         postObjList.filterData.created_from = moment(postObjList.filterData.created_from).format("YYYY-MM-DD 00:00:00");
         postObjList.filterData.created_to = moment(postObjList.filterData.created_to).format("YYYY-MM-DD 23:59:59");
         
         MasterService.post('qc/qc/user/report',postObjList)
         .then( (response) => {
             if (response.data.status == 200) {                 
                 let reportArr = [];                 
                 response.data.data.forEach(function (result) {
                  reportArr.push(result);                        
                 });
                 this.setState({dashboardReport: reportArr});
             }
             this.setState({loading:false});                
         })
         .catch(error => { }); 
  }
  getdashboardcard = async () => {
    DealerService.getdashboardcard().then(result => {
      if (result && result.data && result.data.status == 200) {
        this.setState({ dashboardcardinfo: result.data.data }, () => { })
      }
      else {
        this.handelError(this, result.data);
      }
    }).catch(error => {

    });
  }
  handleChange = (sname, ovalue) => {
    let formData = this.state.formData;
    formData[sname] = ovalue.value;
    this.setState({formData}, () => {
      this.getdashboardleadcard(ovalue.value);
    });
  }
  getdashboardleadcard = async (oldReportDays) => {
    let dashboardCountJson = { "filter_by": oldReportDays };
    DealerService.getdashboardleadcard(dashboardCountJson).then(result => {
      if (result && result.data && result.data.status == 200) {
        this.setState({ dashboardleadcard: result.data.data }, () => { })
      }
      else {
        this.handelError(this, result.data);
      }
    }).catch(error => {

    });
  }
  handleDateChange = (date, sname) => {
    let filterData = this.state.filterData;
    filterData[sname] = date;
    //console.log(date)
    this.setState({ filterData:filterData  },()=>{
      console.log(this.state.filterData)
    });
  }
  resetFilterForm = ()=>{
    this.setState({filterData: {}},()=>{
      this.getUserReport();
    });
    
  }
  searchFilterForm = async()=>{    
    //console.log('search data------',this.state.filterData);
    await this.getUserReport();
  }
  render() {
    const { filterData } = this.state;

    return (
      <div className="">
        <div className={this.state.loading?"container-fluid loading":"container-fluid" }>
        <h1 className="card-title">{this.props.t('dashboard.team_efficiency')} : </h1>
          <div className="search-wrap">
            <div className="row">
            <div className="col-sm-6 col-md-2">
                <DatePicker
                   selected={filterData.created_from}
                  onChange={(date) => this.handleDateChange(date, 'created_from')}
                  name="created_from"
                  id="created_from"
                  autocompleate="off"
                  placeholderText={this.props.t('dashboard.From')}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                   startDate={filterData.created_from}
                   endDate={filterData.created_to}
                   maxDate={filterData.created_to}
                />
              </div>
              <div className="col-sm-6 col-md-2 ">
                <DatePicker
                   selected={filterData.created_to}
                  onChange={(date) => this.handleDateChange(date, 'created_to')}
                  name="created_to"
                  id="created_to"
                  autocompleate="off"
                  placeholderText={this.props.t('dashboard.To')}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                   endDate={filterData.created_to}
                   minDate={filterData.created_from}
                />
              </div>
              <div className="col-sm-6 col-md-2">
                <button type="button"  onClick={this.searchFilterForm} className="btn btn-primary" >{this.props.t('dashboard.search')}</button>
                <button type="button" onClick={this.resetFilterForm} className="btn btn-reset" >{this.props.t('dashboard.reset')}</button>
              </div>
            </div>
          </div>
            <div id="table_data"> 
            <h4 className="card-title">{this.props.t('qualityControl.dealer')}</h4>
              <div className="card  mrg-T20">
                <div className="">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                          <th>#</th>
                          <th>{this.props.t('qualityControl.user')}</th>
                          <th>{this.props.t('qualityControl.qc_done')}</th>
                          {/* <th>{this.props.t('qualityControl.'+CONF_VAL.individual)}</th> */}
                          {/* <th>{this.props.t('qualityControl.dealer')}</th> */}
                          <th className="approved_th">{this.props.t('dashboard.approved')}</th>
                          <th>{this.props.t('dashboard.'+CONF_VAL.rejected)}</th>
                          <th>{this.props.t('dashboard.partiallyApproved')}</th>
                          <th>{this.props.t('dashboard.'+CONF_VAL.removed)}</th>
                          <th>{this.props.t('dashboard.'+CONF_VAL.reg_filled)}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.dashboardReport.map((reportData, index) => 
                      <tr key={index} >
                        <td>{index+1}</td>
                        <td>{reportData.user_name}</td>
                        <td>{reportData.total_qc} </td>
                        {/* <td>{reportData.individual_type}</td> */}
                        {/* <td>{reportData.dealer_type}</td> */}
                        <td className="approved4">{reportData.approved}</td>
                        <td className="rejected4">{reportData.rejected}</td>
                        <td className="partially4">{reportData.partially_approved}</td>
                        <td>{reportData.removed}</td>                  
                        <td>{reportData.reg_filled}</td>
                      </tr>  
                    )}                                                      
                    </tbody>
                </table>
              </div>
            </div>
          </div>
          <div id="table_data">   
          <h4 className="card-title">{this.props.t('qualityControl.'+CONF_VAL.individual)}</h4>
              <div className="card  mrg-T20">
                <div className="">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                          <th>#</th>
                          <th>{this.props.t('qualityControl.user')}</th>
                          <th>{this.props.t('qualityControl.qc_done')}</th>
                          {/* <th>{this.props.t('qualityControl.'+CONF_VAL.individual)}</th> */}
                          {/* <th>{this.props.t('qualityControl.dealer')}</th> */}
                          <th className="approved_th">{this.props.t('dashboard.approved')}</th>
                          <th>{this.props.t('dashboard.'+CONF_VAL.rejected)}</th>
                          <th>{this.props.t('dashboard.partiallyApproved')}</th>
                          <th>{this.props.t('dashboard.'+CONF_VAL.removed)}</th>
                          <th>{this.props.t('dashboard.'+CONF_VAL.reg_filled)}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.dashboardReport.map((reportData, index) => 
                      <tr key={index} >
                        <td>{index+1}</td>
                        <td>{reportData.user_name}</td>
                        <td>{reportData.total_ind} </td>
                        {/* <td>{reportData.individual_type}</td> */}
                        <td className="approved4">{reportData.approved_ind}</td>
                        <td className="rejected4">{reportData.rejected_ind}</td>
                        <td className="partially4">{reportData.partially_approved_ind}</td>
                        <td>{reportData.removed_ind}</td>                  
                        <td>{reportData.reg_filled_ind}</td>
                      </tr>  
                    )}                                                      
                    </tbody>
                </table>
              </div>
            </div>
          </div>            
          
        </div>
      </div>
    
    );
  }
}
const mapStateToProps = state => {
  return {
    name: state.name,
    user_loggedin: state.userLoggedIn
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
  }
}
// export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default withTranslation('qc')(connect(mapStateToProps, mapDispatchToProps)(Report));
