import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

// import Carousel from './GallaryCarousal';//deleteme
import SellerInformation from './SellerInformation';
// import Carousel from './../inventory/component/ListingCarousal';
import InputField from '../elements/InputField';
import CheckBox from './../elements/CheckBox';
import RadioBox from './../elements/Radiobox';
import Button from './../elements/Button';
import ModalPopup from './../elements/Popup';
import RejectReason from './RejectReason';

import GallaryCarousel from './GallaryCarousal';
import MasterService from './../../service/MasterService';
import { toast } from 'react-toastify';
import secureStorage from './../../config/encrypt';
import {CONF_VAL} from '../../config/constant';
import Select from 'react-select';
import { API_URL } from './../../config/constant';
import dateFormat from 'dateformat';
import { Editor } from '@tinymce/tinymce-react';
import {findMatch} from '../common/Utils';

const searchtype = [
    { value: '1', label: 'Zigwheel'},
    { value: '2', label: 'Carmudi' },

  ];
class QualityControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showgallerymodal: false,
            showconfirmmodal:false,
            totalQc:{needQc:0,redoQc:0,pendingQC: 0},
            showmore:[],
            showrejectreason:false,
            showSellerDeatil:false,
            largeClass:false,
            picWithIssue:false,
            tagProfile:false,
            fillRegNo: false,
            reviewDone:false,
            carImages: [],
            userInfo:{},
            carInventoryDetail:{},
            carPrice:0,
            carDescription:'',
            carDescriptionInvalid:false,
            numberPlatePriceValidation:{},
            carPriceInvalid:false,
            regNoInvalid:false,
            issueTypeList:[],
            inventoryIssueList:{},
            rejectedImageIssue:[],
            activeQcType : {},
            activeSearchType: false,
            qcData:{reg_no:""},
            qcType :{},
            profileCheck:{},
            imageIssue:[],
            loading: false,
            data_found:true,
            langData: {},
            approveButtonDisable: false,
            excludeCarId :[],
            carApprovalStates:[],
            searchtype:"1",
            searchbyid:"",
            isOpenState:{},
            userLogState:[],
            tenureList:[],
            statusClassname: {
                'active': 'nav-item active',
                'removed': 'nav-item',
                'all': 'nav-item'
            },
            rejectInventoryCommentModal: false,
            rejectReason: '',
            rejectComment: ''
        }  
        this.showmoreAction = this.showmoreAction.bind(this); 
    }
    componentDidMount = async () => {
        if(secureStorage.getItem('loginUserInfo')){            
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            //await this.listUserLog();  
            await this.setQcType();                       
        }
        else{            
            return (<Redirect to={'/login'} />);
        }
    }

    getQcInventoryList = async() => {
        let langCode = this.state.langData.lang;
        let headerObj = {"accept-language":langCode}; 
        this.setState({loading:true}); 
        let postObj = this.state.activeQcType;
        await this.removeSearchCarFromExcludedCar();

        let excludedCarId = this.state.excludeCarId;
        // if(postObj.search==true){
        //     let index = excludedCarId.indexOf(parseInt(this.state.searchbyid));            
        //     if(index >-1)
        //     {
        //         excludedCarId.splice(index, 1);
        //     }
        // }
        postObj.exclude_car_id = excludedCarId;        
        //console.log(postObj);
        MasterService.post('inventory/inventory/qc_inventory', postObj,headerObj)
            .then( (response) => {  
                this.setState({loading:false});   
                if (response.data.status == 200 &  response.data.data.length > 0 && response.data.data[0].id>0) {
                    let profileUrl = response.data.data[0].car_profile_image||'';
                    let regNoStatus = (response.data.data[0].reg_no)? true: false;
                    let qcData = {...this.state.qcData};
                    qcData.reg_no = (response.data.data[0].reg_no)? response.data.data[0].reg_no: '';
                    //let totalQc = {...this.state.totalQc};
                    //totalQc ={needQc :response.data.data[0].qc_status_count.no_qc,redoQc:response.data.data[0].qc_status_count.redo_qc};
                    let tagProfile = false;
                    let carImageListArr = [];
                    let carInventoryData = {
                        inventoryId : response.data.data[0].id,
                        carHash:  response.data.data[0].car_hash,
                        dealerIdHash:  response.data.data[0].dealer_id_hash,
                        webUrl : response.data.data[0].web_url,
                        dealerId: response.data.data[0].dealer_id,
                        mmv : (response.data.data[0].make +' ' +response.data.data[0].modelVersion +' '+ 
                        response.data.data[0].fuel_type).split('null').join('') ,
                        car_title: response.data.data[0].car_title||'',
                        make : response.data.data[0].make||'',
                        model : response.data.data[0].model||'',
                        modelVersion : response.data.data[0].modelVersion||'',
                        price : response.data.data[0].display_price || response.data.data[0].car_price ,
                        down_payment: (response.data.data[0].down_payment > 0)? response.data.data[0].down_payment: '',
                        emi: (response.data.data[0].emi_amount > 0)?response.data.data[0].emi_amount : '',
                        emi_month: response.data.data[0].total_emi,
                        number_type: response.data.data[0].number_type,
                        year : response.data.data[0].make_year||'',
                        kmDriven: response.data.data[0].km_driven,
                        carPrice : response.data.data[0].car_price,
                        carDescription : response.data.data[0].car_description ? response.data.data[0].car_description.split('null').join(''):'',                      
                        color : response.data.data[0].uc_colour||'',
                        created_date: response.data.data[0].created_date,
                        dealer_name : response.data.data[0].dealer_name||'',
                        bro_name : response.data.data[0].bro_name||'',
                        carRegNo :response.data.data[0].reg_no|| '',
                        transmission : response.data.data[0].transmission|| '',
                        engine_capacity : response.data.data[0].engine_capacity|| '',
                        drive_type : response.data.data[0].drive_type|| '',
                        car_body_type: response.data.data[0].car_body_type|| '',
                        fuel_type: response.data.data[0].fuel_type|| '',
                        owner_name: response.data.data[0].owner_name|| '',
                        sms_mobile: response.data.data[0].sms_mobile|| '',
                        owner_email: response.data.data[0].owner_email|| '',
                        owner_source: response.data.data[0].owner_source|| '',
                        whatsapp_mobile: response.data.data[0].whatsapp_mobile|| '',
                        dealer_default_language: response.data.data[0].dealer_default_language|| '',
                        ispremium: response.data.data[0].ispremium || '0',
                        user_type: response.data.data[0].type,
                        is_feature_value: response.data.data[0].ispremium || '0',
                        qc_status:response.data.data[0].qc_status,
                        certification_status:response.data.data[0].certification_status,
                        qc_status_comment: response.data.data[0].qc_status_comment || []
                    } ;

                    let usedCarImage = response.data.data[0].usedCarImage||[];
                    if(usedCarImage && Array.isArray(usedCarImage)){
                        usedCarImage.forEach(function (result,index) {
                            let resultData = {...result};
                            let which_url_replace = findMatch(result.url,API_URL.USED_CAR_IMAGE_URLS);
                            let replace_src = which_url_replace ? result.url.replace(API_URL.USED_CAR_IMAGE_URLS, API_URL.CDN_IMAGE_URL) : result.url;
                            resultData.src = replace_src;
                            carImageListArr.push(resultData);
                            if(profileUrl ==result.url && profileUrl!=''){
                                tagProfile =true ;
                                carImageListArr[index].is_profile = 1; 
                            }
                                                       
                        });
                    }                                                   
                    this.setState({
                        carImages: carImageListArr,
                        carInventoryDetail : carInventoryData,
                        carPrice: carInventoryData.carPrice,
                        down_payment: carInventoryData.down_payment,
                        emi: carInventoryData.emi,
                        emi_month: carInventoryData.emi_month,
                        number_type: carInventoryData.number_type,
                        //carDescription: carInventoryData.carDescription.replace(/(<([^>]+)>)/ig, ""),
                        carDescription: carInventoryData.carDescription,
                        fillRegNo : regNoStatus,
                        qcData : qcData,
                        //totalQc : totalQc,
                        approveButtonDisable:false,
                        data_found: true,
                        tagProfile: tagProfile
                    },() => {
                       this.setInventoryIssueData(this.state.carInventoryDetail.inventoryId);
                       this.addUserCarLog({car_id:this.state.carInventoryDetail.inventoryId,
                        qc_user_id :this.state.userInfo.data.user_data.id});

                        if(this.state.activeQcType.qc_for=='dealer' && CONF_VAL.type=='oto'){
                            this.getdealerBroName(this.state.carInventoryDetail.dealerId);
                        }                    
                    });
                }
                else{
                    this.setState({
                        carImages :[],
                        carInventoryDetail:{},
                        fillRegNo:false,
                        qcData:{reg_no:""},
                        loading:false,
                        tagProfile:false,           
                        reviewDone:false,
                        profileCheck:{},
                        approveButtonDisable:true,
                        data_found : false,
                        //totalQc:{needQc:0,redoQc:0},
                    });
                    //toast.error(this.props.t('qualityControl.no_data_found') +"("+this.state.activeQcType.qc_for+")");
                }
                
                
        })
        .catch(error => {
            toast.error(error);
        });
    }
    getdealerBroName =(dealerId)=>{
        let langId = this.state.langData.langId;//3540
        let carInventoryDetail = {...this.state.carInventoryDetail};
         MasterService.post('qc/user/get-sfa-mapped-user',{dealerId:dealerId},{langId:langId})
         .then( (response) => {
             let dealerName ='';
             if (response.data.status == 200 && response.data.data) {   
                carInventoryDetail.bro_name= response.data.data.name;  
                           
                this.setState({carInventoryDetail: carInventoryDetail},() => {
                    //console.log('--------------BRO Name----',carInventoryDetail.bro_name);
                });
             }
                             
         })
         .catch(error => {
             //console.log('-----------------------------error----',error);
        }); 
    }
    getCarPriceValidation = () =>{
        let langId = this.state.langData.langId;
         let headerObj = {langId:langId};
         let query =['min_car_price','plate_number_validation'];
         MasterService.get('core/commonservice/master',{master:query},headerObj)
         .then( (response) => {
             if (response.data.status == 200) {  
                 //console.log('---getCarPriceValidation----',response.data.data)               
                 this.setState({numberPlatePriceValidation: response.data.data},() => {
                 });
             }                
         })
         .catch(error => {
        }); 
    }
    getIssueList = async () => {
        let langId = this.state.langData.langId;
         // get issue type list
         let postObjList = {langId:langId, pointId:3};
         MasterService.post('qc/qc/issue-type/list/',postObjList)
         .then( (response) => {
             if (response.data.status == 200) {                 
                 let qcIssueTypeListArr = [];                 
                 response.data.data.forEach(function (result) {
                     qcIssueTypeListArr.push(result);                        
                 });
                 this.setState({issueTypeList: qcIssueTypeListArr},() => {
                     //console.log(thisObj.state.issueTypeList);
                 });
            }                
         })
         .catch(error => {
        }); 
    }
    getEmiTenureList() {
        let langId = this.state.langData.langId;
        let headerObj = {langId:langId};
        MasterService.get('core/commonservice/master',{master:["emi_tenure_list"]},headerObj)
        .then((response) => {            
            if (response && response.status == 200 && response.data.status == 200) {
                //console.log('tenureLists********', response.data.data.emi_tenure_list);
                this.setState({ tenureList: response.data.data.emi_tenure_list });
            }
        }).catch((error) => {
            this.setState({ loading: false })
        });
    }
    removeSearchCarFromExcludedCar = async() =>{
        let userLogState = this.state.userLogState;
        let  excludeCarId = this.state.excludeCarId;
        if(userLogState.length>0){
            userLogState.map((item) =>{
                //console.log('--itemmmms--',item);
                if(item.qc_user_id ==this.state.userInfo.data.user_data.id && item.is_open=='1'){
                   //activeQcType.car_id =item.car_id ; 
                   let index = excludeCarId.indexOf(parseInt(item.car_id));
                    if(index >-1)
                        excludeCarId.splice(index, 1);                               
                }
            })
        }       
        if(this.state.activeQcType.search ==true && parseInt(this.state.activeQcType.car_id)>0){
            let index = excludeCarId.indexOf(parseInt(this.state.activeQcType.car_id));
            if(index >-1)
                excludeCarId.splice(index, 1);
            this.setState({excludeCarId:excludeCarId});
        }
    }
     getTotalQc = () =>{
        let headerObj = {"accept-language":this.state.langData.lang};
        MasterService.post('inventory/inventory/qc_status_count',{qc_for:this.props.match.params.type||'dealer'},headerObj)
            .then( (response) => {
                if(response.data.status=='200'){
                    this.setState({totalQc:{                        
                      needQc :response.data.data.no_qc,
                      redoQc: response.data.data.redo_qc,
                      pendingQC: response.data.data.pending_qc || 0,
                    }
                  });
                  
                }
            })
            .catch(error => {
              //this.setState({loading:false});
            });
        }
    setUserInfo(){
        let userData = secureStorage.getItem('loginUserInfo');
        this.setState({userInfo : userData},()=>{
            //console.log(this.state.userInfo.data.id);
        })
    }
    setInventoryIssueData =(inventoryId) => {
        let langId = this.state.langData.langId;
        let postInventoryList = {langId:langId, pointId:3,car_id: inventoryId};
            MasterService.post('qc/qc/car-issue/list/',postInventoryList)
            .then( (response) => {   
                if (response.data.status == 200) {                                                                              
                    response.data.data.issueList.forEach((result) => {
                       this.__setIssueState(result.image_id, result.issue_id)                                                                                             
                    }); 
                    this.setState({carApprovalStates:response.data.data.carStatus })                                      
                }                
            })
            .catch(error => {
            });
    }
    componentWillReceiveProps = async(nextProps) => {
        if(nextProps.history.location.state == 'freshQc'){
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.setState({
                carImages :[],
                carInventoryDetail:{},
                tagProfile:false,           
                reviewDone:false,
                profileCheck:{},
                activeQcType:{},
                searchbyid:"",
                approveButtonDisable:false,
                picWithIssue : false,            
                qcData:{reg_no:""},
                rejectedImageIssue :[],
                excludeCarId :[],
                userLogState :[],
                carPrice:0,
                carDescription:'',
                carDescriptionInvalid:false,
                carPriceInvalid:false,
                regNoInvalid:false,

            });
            await this.setQcType();
          
        }
    }
    __setIssueState = (img_id, issue_id) => {        
        let qcInventoryTypeListArr = {...this.state.inventoryIssueList};
        if(typeof qcInventoryTypeListArr[img_id] == "undefined"){
            qcInventoryTypeListArr = {
                ...qcInventoryTypeListArr,
                [img_id]: []
            }                                
        }                                                
        qcInventoryTypeListArr[img_id].push(issue_id);       
        this.setState({ inventoryIssueList: qcInventoryTypeListArr},() => {
            //console.log(this.state.inventoryIssueList);
        });
    }
    __removeIssueState = (img_id, issue_id) => {
        let qcInventoryTypeListArr = { ...this.state.inventoryIssueList };
        let index = qcInventoryTypeListArr[img_id].indexOf(issue_id);
        if (index > -1) {
            qcInventoryTypeListArr[img_id].splice(index, 1);
        }
        if (img_id && qcInventoryTypeListArr[img_id].length < 1) {
            delete qcInventoryTypeListArr[img_id]
        }

        this.setState({ inventoryIssueList: qcInventoryTypeListArr }, () => {
        });
    }
    handleTabClick = async(data,searchType) => {
        
        let qcType ={...this.state.activeQcType,"type":data};         
        if(!searchType){
            delete qcType['car_id'];
            delete qcType['search'];
            this.setState({searchbyid:''});
        }
        if(this.state.activeQcType.type == data && typeof searchType =="undefined" 
        && !(this.state.activeQcType.hasOwnProperty('search'))){
            return 
        }                   
        this.setState({activeQcType: qcType},() =>{
           // console.log(this.state.activeQcType);            
            this.setQcType();
        })  
        
    }
    qcSearchInventory =() => {

        let searchById = parseInt(this.state.searchbyid,10);
        if(typeof searchById !='undefined' && searchById >0 && searchById !='NaN'){
            let qcType ={...this.state.activeQcType}; 
            qcType.search = true ;
            qcType.car_id = this.state.searchbyid;
            if(this.state.searchtype==2){
                qcType.car_source_type ='carmudi';
            }
            else{
                delete qcType['car_source_type'];
            }
            this.setState({activeQcType: qcType},() =>{
                //console.log(this.state.activeQcType);
                this.setQcType();
            })  
        }
        if(this.state.searchbyid==''){
            this.handleTabClick(this.state.activeQcType.type,false);
        }
    }
    onSubmitSearchInventory =(event) =>{
        event.preventDefault();
        this.qcSearchInventory();
    }
    onKeyPressedEnter(e) {
        if(e.key=='Enter' && CONF_VAL.type=='carmudi'){
            this.qcSearchInventory();
        }
    }
    qcSearchCar = (event) => {
        this.setState({[event.target.name]: event.target.value.replace(/\D/g,'')},()=>{
            //console.log(this.state.searchbyid);
        });
        
      }
    /** set Qc type Dealer or Individual */
    setQcTypeCall =  async() => { 
        let langData = {} ;
        let langOption = secureStorage.getItem('langOption');
        let lang = secureStorage.getItem('lang')
        if(langOption && langOption.length > 0 ){
            langData.langOption = langOption;
            langData.lang = lang;
            langData.langId = (langOption.filter(v => v.iso_code == lang).map(v => v.id))[0];
        }
        
        let qc_for = this.props.match.params.type || 'dealer';
        let type = (this.state.activeQcType.type !=null) ?this.state.activeQcType.type:'need_qc';      
        let qcType ={...this.state.activeQcType,qc_for:qc_for,type:type};
        
        this.setState({activeQcType:qcType,langData:langData},()=>{
            this.getCarPriceValidation();
            this.getQcInventoryList();
            this.getIssueList();            
        });       
    }
    addUserCarLog =(userData) => {
        MasterService.post('qc/qc/user-log/add',userData)
            .then( (response) => {
                if (response.data.status == 200) {

                }                
            })
            .catch(error => {
                this.setState({loading:false})
            }); 
    }
    setQcType =() => {
        this.getTotalQc();

        MasterService.post('qc/qc/user-log/list')
            .then( (response) => {
                if (response.data.status == 200) {
                    let carId =[];
                    let userLogState= this.state.userLogState;
                    //let activeQcType = this.setState.activeQcType;
                    //console.log('---------',activeQcType);
                    //if(response.data.data.length>0){
                        response.data.data.map((item,index)=>{                            
                            userLogState.push(item);
                            //console.log(item.car_id);                           
                            carId.push(item.car_id);
                        }) ; 
                    //}                                       
                    this.setState({excludeCarId:carId,userLogState:userLogState},()=>{                        
                        this.setUserInfo();
                        this.setQcTypeCall();
                        this.getEmiTenureList()
                    })
                }                
            })
            .catch(error => {

            }); 
    }

    setCarImageIssue = (event, issueId,imageData,InventoryId) => {   
        
        let qc_user_id = this.state.userInfo.data.user_data.id;  
        let issueData = {...imageData};
        issueData.issue_id = issueId;
        issueData.car_id = InventoryId;
        issueData.image_id = imageData.id;
        issueData.qc_user_id =qc_user_id;
        delete issueData['id']; 

        MasterService.post('qc/qc/car-issue/add',issueData)
            .then( (response) => {
                if (response.data.status == 200) {
                }                
            })
            .catch(error => {
            });             
        if (event.target.checked) {
            this.__setIssueState(imageData.id, issueId);            
        } else {
            this.__removeIssueState(imageData.id, issueId);
        }
       //console.log(issueData); 
        
    }
    statusClickHandler = (type, statusVal) => {
        this.props.handleStatusChange(statusVal);
        this.setState({
            statusClassname: {
                'active': 'nav-item',
                'removed': 'nav-item',
                'all': 'nav-item',
                [type]: 'nav-item active'
            }
        })
    }   
    showmoreAction = (e, index) => { 
        this.setState({showmore: {
               [index]: !this.state.showmore[index]
        } });
    }
    showLargeImage = () => { 
        this.setState({largeClass: !this.state.largeClass });
    }
    showModal = () => { 
        this.setState({showgallerymodal: true  });
    }
    showSellerDeatil = () => { 
        this.setState({showSellerDeatil: true  });
    }
    showreject = () => { 
        this.setState({showrejectreason: true,reviewDone: false  });
    }
    reviewDone = (event) => {
        let reviewDoneState = { ...this.state.reviewDone };
        if (event.target.checked) {
            reviewDoneState = true;
        }
        else {
            reviewDoneState = false;
        }
        this.setState({ reviewDone: reviewDoneState });
    }
    numberTypeSelect = (event) => {
        let checkedValue = event.currentTarget.value;
        this.setState({ number_type: checkedValue });
    }
    showgallaryModal = async (carId) => {
        await this.getUsedCarImageList(carId);
        this.setState({ showgallerymodal: true });
    }
    closeModal = () => {
        this.setState({ showgallerymodal: false, showrejectreason: false,showSellerDeatil:false, showconfirmmodal:false, rejectInventoryCommentModal:false });
    }

    setProfilePic = (index) => {
        let imgData = [...this.state.carImages];
        imgData.map((item,indexAt) =>{
            imgData[indexAt].is_profile = 0;
        });
        imgData[index].is_profile = 1;
        this.setState({
            carImages: imgData,
            tagProfile : true
        },() => {
            toast.success(this.props.t('qualityControl.'+CONF_VAL.profile_photo_done));
        })
    }
    unsetProfilePic = (index) => {
        let imgData = [...this.state.carImages];
        imgData.map((item,indexAt) =>{
            imgData[indexAt].is_profile = 0;
        });        
        this.setState({
            carImages: imgData,
            tagProfile : false
        },() => {
            toast.success(this.props.t('qualityControl.'+CONF_VAL.profile_photo_removed));
        })
    }
    handleProfileCheckbox =(event) =>{
        let profileCheck = {...this.state.profileCheck};
        if (event.target.checked ){
            profileCheck['type'] = event.target.name;
        }
        this.setState({
            profileCheck:profileCheck
        });
    }

    handleChangetext = (event) => {   
        let qcData = {...this.state.qcData}; 
        let regNoInvalid ={...this.state.regNoInvalid} ;
        let regValidation= this.state.numberPlatePriceValidation.plate_number_validation;

        if (event.target.name=='regnotavailable'){
            if (event.target.checked ){
                qcData.regnotavailable =true;
                qcData.reg_no ='';
                //qcData['reg_no']['disabled'] = true; 
            }else{
                qcData.regnotavailable =false;
                //qcData['reg_no']['disabled'] = false; 
            }
            regNoInvalid = false;
        }
        else if (event.target.name=='reg_no'){            
            qcData.reg_no = event.target.value; 
            //check reg no is valid or not 
            if(!new RegExp(regValidation).test(event.target.value)){
                regNoInvalid = true ;
            } 
            else
            regNoInvalid =false;
        }else if(event.target.name == 'down_payment'){
            let new_dp = event.target.value; 
            this.setState({ down_payment: new_dp });     
        } else if(event.target.name == 'emi'){
            let new_emi_amount = event.target.value; 
            this.setState({ emi: new_emi_amount });     
        }                    
        this.setState({
            qcData:qcData, 
            regNoInvalid:regNoInvalid         
        },()=>{           
            //console.log('regNoInvalid----',regNoInvalid);
        });
    }
    handlePriceChange=(event) => { 
        let price = parseInt(event.target.value.replace(/\D/g,''))||0  ;
        let minimumPrice = this.state.numberPlatePriceValidation.min_car_price||0;
        if(price < minimumPrice ){
            this.setState({carPriceInvalid:true});
        }
        else{
            this.setState({carPriceInvalid:false});
        }          
        this.setState({[event.target.name]: price},()=>{
            //console.log('new car price--',this.state.carPrice);
        });        
    }
    handleDescriptionChange =(event)=>{
        let carDescriptionLength =0;
        this.setState({[event.target.name]: event.target.value},()=>{
            carDescriptionLength = this.state.carDescription.split(' ').length;
            if( this.state.carDescription != this.state.carInventoryDetail.carDescription 
                &&  carDescriptionLength<=100 )
            {
                this.setState({carDescriptionInvalid:true});
            }
            else if (this.state.carDescription==this.state.carInventoryDetail.carDescription || carDescriptionLength>=100)
                this.setState({carDescriptionInvalid:false});
        });
    }

    handleEditorChange = (content, editor) => {    
        let carDescriptionLength =0;
        // console.log(content);
        // //var content = tinymce.trim(body.innerText || body.textContent);
        // console.log(editor.getBody().innerText);
        
        this.setState({carDescription: content || ''},()=>{
            //carDescriptionLength = editor.getBody().innerText.trim().length;
            carDescriptionLength = editor.plugins.wordcount?.body?.getCharacterCount();            
            if( carDescriptionLength > 0 
                &&  carDescriptionLength<100 )
            {
                this.setState({carDescriptionInvalid:true});
            }
            else if (this.state.carDescription==this.state.carInventoryDetail.carDescription || carDescriptionLength>=100)
                this.setState({carDescriptionInvalid:false});
        });
    }

    updateQCarImages =(data,imageId)=>{   
        this.setState({showgallerymodal:false});     
        let currentImages = [...this.state.carImages];
        let imageIndex =0;
        currentImages.map((item,index)=>{
            //if(item)
            if(item.id == imageId ){
                let file_url = data.file_url ;//.replace('https', 'http');
                let itemData ={...item};
                //itemData.file_name = itemData.image_name;
                //itemData.file_url = itemData.src;                
                itemData.image_name =data.file_name;
                itemData.src =file_url; 
                itemData.url =file_url;                
                currentImages[index] = itemData ;  
                //add new image to image log  
                this.addNewImageLog(data,item);   
                imageIndex = index ;    
            }           
        })
        this.setState({
            carImages : currentImages
        },()=>{
            this.setState({showgallerymodal:true,editImageId:imageIndex });  
            //console.log(' after set state' ,this.state.carImages);
        })
    }
    addNewImageLog =(newImageData,oldImageData)=>{        
        let imageData = {
            car_id : this.state.carInventoryDetail.inventoryId,
            new_image_url: newImageData.file_url,
            new_image_name : newImageData.file_name,
            old_image_id : oldImageData.id,
            old_image_url: oldImageData.url,
            old_image_hash: oldImageData.img_hash, 
            created_by :this.state.userInfo.data.user_data.id,           
        }
        MasterService.post('qc/qc/image-log/add',imageData).then( (response) => {
            if (response.data.status == 200) {
                console.log(response.data.data);
            }                
        })
        .catch(function (response){
            this.setState({loading:false})
            toast.error(response);
        }); 
    }
    //on approval function
    handleApproveInventory  = async() =>{       
        this.setState({loading:true,approveButtonDisable:true});
        let { certification_status, qc_status } = this.state.carInventoryDetail;
        if(this.state.regNoInvalid || (this.state.qcData.reg_no=='' && !(this.state.qcData.regnotavailable)) )
        {
            if (certification_status == '1' && (qc_status == '2' || qc_status == '3')) {
                this.setState({ loading: true, approveButtonDisable: true });
            }
            else {
                this.setState({ loading: false, approveButtonDisable: false });
                return toast.error(this.props.t('qualityControl.' + CONF_VAL.reg_no_invalid));
            }
        }
        if(this.state.carPriceInvalid)
        {      
            this.setState({loading:false,approveButtonDisable:false});
            return  toast.error(this.props.t('qualityControl.car_price_error')+ this.state.numberPlatePriceValidation.min_car_price);
        }
        if(this.state.carDescriptionInvalid)
        {      
            this.setState({loading:false,approveButtonDisable:false});
            return  toast.error(this.props.t('qualityControl.car_description_error'));
        }
        if(!(this.state.profileCheck.type || this.state.tagProfile) ||  (!(this.state.reviewDone || this.state.picWithIssue)) ||
         (this.state.qcData.reg_no=='' && !(this.state.qcData.regnotavailable)) ){
            if (certification_status == '1' && (qc_status == '2' || qc_status == '3')) {
                this.setState({ loading: true, approveButtonDisable: true });
            } else {
                this.setState({ loading: false, approveButtonDisable: false });
                return toast.error(this.props.t('qualityControl.qc_not_done'));
            }
        }
        
        let inventoryId  =this.state.carInventoryDetail.inventoryId;
        let qcData = {...this.state.qcData};
        let qc_user_id = this.state.userInfo.data.user_data.id;
        let pointStatus= {pointStatus1:'0',pointStatus2:'0', pointStatus3 :'0'} ;
        
        if(this.state.rejectedImageIssue.length > 0){
           await this.addCarStateMappingLog('flagged');
           await this.addMultipleImageIssue();    
       }
        // add issue if reg no not avaialble checked
        if(qcData.regnotavailable){                            
            // call  api to add issue for reg no not available
            await this.addCarIssue({issue_id:15,car_id:inventoryId,qc_user_id:qc_user_id});
        }
        else {
            pointStatus.pointStatus2 ='1';
            let addQcData ={car_id:inventoryId,tag_name :'reg_no',tag_value:qcData.reg_no,qc_user_id:qc_user_id,is_latest:"1" } ;
            // add reg no log
            await this.addQcLog(addQcData);
            if(this.state.carInventoryDetail.carRegNo != this.state.qcData.reg_no){
                await this.addQcLog({car_id:inventoryId,tag_name :'reg_no_old',tag_value:this.state.carInventoryDetail.carRegNo,qc_user_id:qc_user_id,is_latest:"1" });
            }                     
        }
        //add profile pic not found issue 
        if(this.state.profileCheck['type'] =='profile_not_found'){
            // call  api to add issue for reg no not available
           await this.addCarIssue({issue_id:1,car_id:inventoryId,qc_user_id:qc_user_id});
        } 

        //DP/EMI/Emi-month/Numer type log
        if( this.state.down_payment != this.state.carInventoryDetail.down_payment){
            await this.addQcLog({car_id:inventoryId,tag_name :'down_payment',tag_value:this.state.down_payment,qc_user_id:qc_user_id,is_latest:"1" });  
            await this.addQcLog({car_id:inventoryId,tag_name :'down_payment_old',tag_value:this.state.carInventoryDetail.down_payment,qc_user_id:qc_user_id,is_latest:"1" });
        }
        if( this.state.emi != this.state.carInventoryDetail.emi){
            await this.addQcLog({car_id:inventoryId,tag_name :'emi',tag_value:this.state.emi,qc_user_id:qc_user_id,is_latest:"1" });  
            await this.addQcLog({car_id:inventoryId,tag_name :'emi_old',tag_value:this.state.carInventoryDetail.emi,qc_user_id:qc_user_id,is_latest:"1" });
        }
        if( this.state.emi_month != this.state.carInventoryDetail.emi_month){
            await this.addQcLog({car_id:inventoryId,tag_name :'emi_month',tag_value:this.state.emi_month,qc_user_id:qc_user_id,is_latest:"1" });  
            await this.addQcLog({car_id:inventoryId,tag_name :'emi_month_old',tag_value:this.state.carInventoryDetail.emi_month,qc_user_id:qc_user_id,is_latest:"1" });
        }
        if( this.state.number_type != this.state.carInventoryDetail.number_type){
            await this.addQcLog({car_id:inventoryId,tag_name :'number_type',tag_value:this.state.number_type,qc_user_id:qc_user_id,is_latest:"1" });  
            await this.addQcLog({car_id:inventoryId,tag_name :'number_type_old',tag_value:this.state.carInventoryDetail.number_type,qc_user_id:qc_user_id,is_latest:"1" });
        }
        //End

        // add log if car price changed
        let minimumPrice = this.state.numberPlatePriceValidation.min_car_price||0;
        if(this.state.carPrice >= minimumPrice && this.state.carPrice != this.state.carInventoryDetail.carPrice ){
            // add car price log
            await this.addQcLog({car_id:inventoryId,tag_name :'car_price',tag_value:this.state.carPrice.toString(),qc_user_id:qc_user_id,is_latest:"1" });         
            await this.addQcLog({car_id:inventoryId,tag_name :'car_price_old',tag_value:this.state.carInventoryDetail.carPrice.toString(),qc_user_id:qc_user_id,is_latest:"1" });         
        }
        // add log if car description changed
        let carDescriptionLength = this.state.carDescription.split(' ').length;
        if( this.state.carDescription != this.state.carInventoryDetail.carDescription && carDescriptionLength>=100 ){
            // add car price log
            await this.addQcLog({car_id:inventoryId,tag_name :'car_description',tag_value:this.state.carDescription,qc_user_id:qc_user_id,is_latest:"1" });         
            await this.addQcLog({car_id:inventoryId,tag_name :'car_description_old',tag_value:this.state.carInventoryDetail.carDescription,qc_user_id:qc_user_id,is_latest:"1" });         
            
        }

        // add log if is_feature change
        if(this.state.carInventoryDetail.ispremium != this.state.carInventoryDetail.is_feature_value){
            await this.addQcLog({car_id:inventoryId,tag_name :'is_feature',tag_value:this.state.carInventoryDetail.ispremium,qc_user_id:qc_user_id,is_latest:"1" });         
            await this.addQcLog({car_id:inventoryId,tag_name :'is_feature_old',tag_value:this.state.carInventoryDetail.is_feature_value,qc_user_id:qc_user_id,is_latest:"1" });
        }
        

        pointStatus.pointStatus1 =(this.state.tagProfile || (this.state.profileCheck['type'] =='profile_correct')) ? '1':'2' ;       
        pointStatus.pointStatus3 = (this.state.reviewDone) ? '1' :'2';        

         // add tag profile statussearchInventory
        this.addCarMappingStatus(inventoryId,1,qc_user_id,pointStatus.pointStatus1);
         // add reg no status
        this.addCarMappingStatus(inventoryId,2,qc_user_id,pointStatus.pointStatus2);
         // add review done status
        this.addCarMappingStatus(inventoryId,3,qc_user_id,pointStatus.pointStatus3);
        this.addUserCarLog({car_id:this.state.carInventoryDetail.inventoryId,
            qc_user_id :this.state.userInfo.data.user_data.id,update:true
        })


        let imageLosgResult = await this.addMultipleQcLog({car_id:inventoryId,tag_name :'image',car_images:this.state.carImages,qc_user_id:qc_user_id,is_latest:"1"});

        // call add image log api for all images
        /*this.state.carImages.map(async(imgData, index) =>{
            if(imgData.url){
                await this.addQcLog({car_id:inventoryId,tag_name :'image',tag_value:imgData.url,qc_user_id:qc_user_id,is_latest:"1" });    
            }
            
        },()=>{
            //console.log('issue saved');
            //toast.success('All Images Issue successfully reported');
        }); */
        
        let updateStaus = await this.updateQcInventoryStatus();
        if(this.state.rejectedImageIssue.length > 0){
           let notifyStatus  =  await this.sendNotification();
        } 
        
        //let addRejectedImage = await this.saveAllRejectedImages();
        toast.success(this.props.t('qualityControl.qc_done'));                 
        this.setState({
            //loading:false,
            tagProfile:false,           
            reviewDone:false,
            profileCheck:{},
            activeQcType:{},
            searchbyid:"",
            approveButtonDisable:false,
            picWithIssue : false,            
            qcData:{reg_no:""},
            rejectedImageIssue :[],
            excludeCarId :[],
            userLogState :[],
            carPrice:0,
            carDescription:'',
            carDescriptionInvalid:false,
            carPriceInvalid:false,
            regNoInvalid:false,
            //totalQc:{needQc:0,redoQc:0},
            //carInventoryDetail :{}
        });
        this.setQcType();  
        
    }
    updateQcInventoryStatus = async() => {
        let return_status = true;
        let inventoryIssueList = this.state.inventoryIssueList;
        let images = [];
        let rejected_images = [];
        this.state.carImages.map((imgData, index) =>{
            //check if reject all selected
            if(this.state.rejectedImageIssue.length > 0){
                let rejectedData = {...imgData};
                let issueList = [];
                let rejectedImageIssue= this.state.rejectedImageIssue;
                rejectedImageIssue.map((issueId) =>{
                    let issue ={ id : issueId } ;
                    issue.issue_label = (this.state.issueTypeList.filter(v => v.id == issueId).map(v => v.issue_label))[0];
                    issue.issue_name = (this.state.issueTypeList.filter(v => v.id == issueId).map(v => v.issue_name))[0];
                    issueList.push(issue);
                });
                rejectedData.issueList= issueList;
                rejected_images.push(rejectedData);

            }
            else{

                if(typeof inventoryIssueList[imgData.id] == "undefined"){
                    //let imageData = 
                    if(imgData.is_profile == 1){
                        images.unshift(imgData);
                    }
                    else{
                        images.push(imgData);
                    }
                }
                else{
                    let rejectedData= imgData;
                    let issueList = [];
                    inventoryIssueList[imgData.id].map((issueId) =>{
                        let issue ={ id : issueId } ;
                        issue.issue_label = (this.state.issueTypeList.filter(v => v.id == issueId).map(v => v.issue_label))[0];
                        issue.issue_name = (this.state.issueTypeList.filter(v => v.id == issueId).map(v => v.issue_name))[0];
                        issueList.push(issue);
                    })
                    
                    rejectedData.issueList= issueList;
                    rejected_images.push(rejectedData);
    
                }
    
            }


        })

        let qc_state = (rejected_images.length > 0) ? this.state.activeQcType.type :'approved';
        return_status = this.addCarStateMappingLog(qc_state);
        
        let updatedData ={car_id:this.state.carInventoryDetail.inventoryId};
        if(this.state.carInventoryDetail.carRegNo != this.state.qcData.reg_no){
            updatedData.reg_no = this.state.qcData.reg_no;
        } 
        let minimumPrice = this.state.numberPlatePriceValidation.min_car_price||0;
        if(this.state.carPrice>=minimumPrice && this.state.carPrice != this.state.carInventoryDetail.carPrice){
            updatedData.car_price = this.state.carPrice;
        } 
        if(this.state.carDescription != this.state.carInventoryDetail.carDescription && 
            this.state.carDescription.length>=100)
        {
            updatedData.description = this.state.carDescription;
        } 
        if( this.state.down_payment != this.state.carInventoryDetail.down_payment){
            updatedData.down_payment = this.state.down_payment;
        }
        if( this.state.emi != this.state.carInventoryDetail.emi){
            updatedData.emi_amount = this.state.emi;
        }
        if( this.state.emi_month != this.state.carInventoryDetail.emi_month){
            updatedData.total_emi = this.state.emi_month;
        }
        if( this.state.number_type != this.state.carInventoryDetail.number_type){
            updatedData.number_type = this.state.number_type;
        }

        updatedData = {...updatedData,images:images,rejected_images:rejected_images} 

        //console.log('---pushQcDoneInventory----',updatedData); 
        return_status = this.pushQcDoneInventory(updatedData);
        return return_status ;

    }
    pushQcDoneInventory = (updateData) => {
        let langCode = this.state.langData.lang;
        let headerObj = {"accept-language":langCode};
        updateData.user_type = this.state.carInventoryDetail.user_type;
        if(this.state.activeQcType.qc_for == 'individual'){
            updateData.is_feature = this.state.carInventoryDetail.ispremium;
        }        
        //console.log('update-Data =',updateData);
        MasterService.post('inventory/inventory/push_qc_done_inventory',updateData,headerObj)
        .then( (response) => {
            if (response.data.status == 200) {
                //console.log(response.data.data);
                return true;
            }                
        })
        .catch(function (response){
            //toast.error(response);
            this.setState({loading:false})
            return false;
        }); 
    } 
    addCarStateMappingLog = (qc_state, callback) =>{
        let qc_user_id = this.state.userInfo.data.user_data.id;
        let carData = this.state.carInventoryDetail;
        let url = CONF_VAL.type=='oto' && this.state.activeQcType.qc_for=='individual' ? this.state.carInventoryDetail.webUrl.oto : this.props.t('qualityControl.'+ CONF_VAL.dealer_url);
        let overall_status = (qc_state=="approved")? "1":"0";
        let logData = {
            car_id:this.state.carInventoryDetail.inventoryId,
            qc_user_id:qc_user_id,
            qc_state : qc_state,
            dealer_stock: (this.state.activeQcType.qc_for=='dealer')? "1":"0",
            individual_stock : (this.state.activeQcType.qc_for=='individual')? "1":"0",
            overall_status: overall_status,
            title :  carData.car_title||'',
            color: carData.color||'',
            name : carData.dealer_name || carData.owner_name,
            price : carData.price||'',
            mobile : carData.sms_mobile||'',
            owner_email : carData.owner_email ||'',
            url : url||'',
            make: carData.make||'',
            model: carData.model||'',
            modelVersion: carData.modelVersion||'',
            year : carData.year||'',
            owner_source:carData.owner_source||'',
            whatsapp_mobile: carData.whatsapp_mobile||'',
            default_language: carData.dealer_default_language||'',
            rejectReason: this.state.rejectReason || '',            
            comment: this.state.rejectComment || '',
            webUrl: carData.webUrl || ''
        }
        MasterService.post('qc/qc/state-maping/add',logData).then( (response) => {
            if (response.data.status == 200) {
                if(callback){
                    callback(true);
                }                
            }                
        })
        .catch(function (response){
            this.setState({loading:false})
            //toast.error(response);
            if(callback){
                callback(false);
            }    
        }); 
    }
    sendNotification =() => {
        let carData = this.state.carInventoryDetail;
        let itemData = {
            issueItem:this.state.rejectedImageIssue,
            title :  carData.car_title,
            color: carData.color,
            name : carData.dealer_name || carData.owner_name,
            price : carData.price||'',
            mobile : carData.sms_mobile||'',
            owner_email : carData.owner_email ||'',
            car_id: carData.inventoryId||'',
            owner_source:carData.owner_source||''
        }
        MasterService.post('qc/qc/reject/notify',itemData).then( (response) => {
            if (response.data.status == 200) {
                console.log(response.data.data);
            }                
        })
        .catch(function (response){
            this.setState({loading:false})
            //toast.error(response);
        }); 
    }
    addCarIssue =(issueData) => {
        MasterService.post('qc/qc/car-issue/add',issueData).then( (response) => {
            if (response.data.status == 200) {
                //console.log(response.data.data);
            }                
        })
        .catch(function (response){
            this.setState({loading:false})
            //toast.error(response);
        }); 
    }
    addMultipleQcLog = async(qcData) => {        
        MasterService.post('qc/qc/log/add-multiple',qcData).then( (response) => {
            if (response.data.status == 200) {
                //console.log(response.data.data);
            }                
        })
        .catch(function (response){
            this.setState({loading:false})
            //toast.error(response);
        }); 
    }
    addQcLog = async(qcData) => {        
        MasterService.post('qc/qc/log/add',qcData).then( (response) => {
            if (response.data.status == 200) {
                //console.log(response.data.data);
            }                
        })
        .catch(function (response){
            this.setState({loading:false})
            //toast.error(response);
        }); 
    }
    addCarMappingStatus =(car_id,qc_point_id,qc_user_id,qc_point_status) => {
        let mappingData = {car_id:car_id,qc_user_id:qc_user_id, qc_point_id:qc_point_id,qc_point_status:qc_point_status}
        MasterService.post('qc/qc/point-maping/add',mappingData)
            .then( (response) => {
                if (response.data.status == 200) {
                    //console.log(response.data.data);
                }                
            })
        .catch(function (response){
            this.setState({loading:false})
            //toast.error(response);
        }); 
    }
    handleRejectAllImages = (item,itemId,isChecked) => {
        let issueData = [...this.state.rejectedImageIssue] ;
        if(isChecked && issueData.indexOf(itemId) == -1){
            issueData.push(itemId);
        }
        else if(issueData.indexOf(itemId) > -1){
            issueData.splice(issueData.indexOf(itemId), 1);
        }          
        this.setState({
            rejectedImageIssue:issueData
        });
    }
    saveAllRejectedImages = () =>{
        if(this.state.rejectedImageIssue.length > 0){
            let qc_user_id = this.state.userInfo.data.user_data.id;
            let rejected_images = [];
            this.setState({picWithIssue : true})       
            
    
            this.state.carImages.map(async(imgData, index) =>{
                let rejectedData = await this.setMultupleCarImageIssue(imgData);
                rejected_images.push(rejectedData);  
            },()=>{              
            });
            this.addCarStateMappingLog('flagged');    
        }

    }
    saveAllReject = () => {
        if(this.state.rejectedImageIssue.length == 0){            
            this.setState({picWithIssue : false})  
            //toast.error(this.props.t('qualityControl.select_one_issue'));
            this.closeModal();
        }
        else{
         
            this.closeModal();
            this.setState({
                loading:false,
                //tagProfile:false,           
                reviewDone:false,
                //profileCheck:{},
                approveButtonDisable:false,
                //showconfirmmodal:false,                
                picWithIssue : true,                
            });
            // this.setQcType();
        }
    }
    addMultipleImageIssue = () => {
        if (this.state.rejectedImageIssue.length > 0) {
            let postData = {
                car_id: this.state.carInventoryDetail.inventoryId,
                car_issues: this.state.rejectedImageIssue,
                car_images: this.state.carImages,
                qc_user_id: this.state.userInfo.data.user_data.id
            }
            MasterService.post('qc/qc/car-issue/add-multiple', postData)
                .then((response) => { })
                .catch(function (response) {
                    //toast.error('Error');
                });
        }        
    }
    setMultupleCarImageIssue = async(imageData) => {
        let inventoryId  =this.state.carInventoryDetail.inventoryId;
        let rejectedData = {...imageData};
        let issueList = [];                
        let qc_user_id = this.state.userInfo.data.user_data.id ;
        this.state.rejectedImageIssue.map((issueId, index)=>{
            let issue ={ id : issueId } ;
                issue.issue_label = (this.state.issueTypeList.filter(v => v.id == issueId).map(v => v.issue_label))[0];
                issue.issue_name = (this.state.issueTypeList.filter(v => v.id == issueId).map(v => v.issue_name))[0];
                issueList.push(issue);

            
           let issueData = {...imageData};
            issueData.issue_id = issueId;
            issueData.car_id = inventoryId;
            issueData.image_id = imageData.id;
            issueData.qc_user_id = qc_user_id;
            delete issueData['id'];             
            MasterService.post('qc/qc/car-issue/add',issueData)
                .then( (response) => {
                    if (response.data.status == 200) {
                        
                    }                
                })
                .catch(function(response) {
                    //toast.error('Error');
            });            
        });
        rejectedData.issueList = issueList;
        return rejectedData;

       
    }
    openGalleryImage =(index) =>{
        this.setState({showgallerymodal:true,editImageId:index });     

    }
    handleDeleteInventory = async () => {
        if (((this.state.rejectReason) && (this.state.rejectReason != 'Others')) || ((this.state.rejectReason == 'Others') && (this.state.rejectComment.trim() != ''))) {
            this.setState({ loading: true });
            this.addCarStateMappingLog('delisted', (removeStatus) => {
                if (removeStatus) {
                    this.pushRemovedInventory((status) => { });
                    toast.success(this.props.t('qualityControl.' + CONF_VAL.remove_success))
                }
                else
                    toast.error(this.props.t('qualityControl.error'));

                this.setState({
                    loading: false,
                    tagProfile: false,
                    reviewDone: false,
                    profileCheck: {},
                    approveButtonDisable: false,
                    showconfirmmodal: false,
                    picWithIssue: false,
                    qcData: { reg_no: "" },
                    rejectedImageIssue: [],
                    excludeCarId: [],
                    userLogState: [],
                    carPrice: 0,
                    carDescription: '',
                    carDescriptionInvalid: false,
                    carPriceInvalid: false,
                    regNoInvalid: false,
                    dpInvalid: false,
                    emiInvalid: false,
                    emiMonthInvalid: false,
                    nummberTypeInvalie: false
                    //totalQc:{needQc:0,redoQc:0},                
                });
                this.setQcType();

            });
        } else if (this.state.rejectReason == '') {
            toast.error(this.props.t('qualityControl.remove_reason_required'));
        } else{
            toast.error(this.props.t('qualityControl.remove_comment_required'));
        }
    }
    confirmDeleteInventory=()=>
    {
        this.setState({showconfirmmodal:true});
    }

    pushRemovedInventory = (callback) =>{
        let carHash = this.state.carInventoryDetail.carHash; 
        let dealerHash = this.state.carInventoryDetail.dealerIdHash;        
        let headerObj = {"accept-language":this.state.langData.lang};
        let removeData = {car_hash:carHash,dealer_id_hash:dealerHash,type:this.state.activeQcType.qc_for}
        //console.log(removeData);
        MasterService.post('inventory/inventory/removeqcusedstockcar',removeData,headerObj)
        .then( (response) => {
            if (response.data.status == 200) {
                console.log('removeqcusedstockcar=',response.data.data)
                callback(true);
            }                
        })
        .catch(function (response){
            this.setState({loading:false})
            toast.error(response);
            callback(false);
        }); 
    }
    handleSelect(key) {
        this.setState({ key });
      }
    
    handleChange = (sname, ovalue) => {
        this.setState({
          [sname]: ovalue.value
        });        
    }
    
    handleFeaturedCheckbox = (event) =>{
        let mark_featured = '0';
        if (event.target.checked ){
            mark_featured = '1';
        }        
        this.setState({
            carInventoryDetail: {
                ...this.state.carInventoryDetail,
                ispremium: mark_featured
            }
        });
    }

    takeRejectComment = () => {
        this.setState({rejectInventoryCommentModal:true});
    }

    handleRejectCommentChange = (event) => {
        this.setState({rejectComment: event.target.value});
    }

    rejectInventory = () => {
        if (((this.state.rejectReason) && (this.state.rejectReason != 'Others')) || ((this.state.rejectReason == 'Others') && (this.state.rejectComment.trim() != ''))) {
           this.pushRejectedInventory()
        } else if (this.state.rejectReason == '') {
            toast.error(this.props.t('qualityControl.reject_reason_required'));
        } else {
            toast.error(this.props.t('qualityControl.comment_required'));
        }
    }

    logRejectedInventory = () => {                
        this.addCarStateMappingLog('rejected', (logStatus) => {    
            this.resetState();                              
        });  
    }

    pushRejectedInventory = () =>{
        this.setState({ loading: true });
        let carHash = this.state.carInventoryDetail.carHash;
        let dealerHash = this.state.carInventoryDetail.dealerIdHash;
        let headerObj = { "accept-language": this.state.langData.lang };
        let comment = '';
        if (this.state.rejectComment) {
            comment = this.state.rejectComment.trim()+' | '+this.state.rejectReason;
        }else{
            comment = this.state.rejectReason;
        }
        let rejectData = {car_hash:carHash,dealer_id_hash:dealerHash,type:this.state.activeQcType.qc_for, comment: comment}
        //console.log(removeData);
        MasterService.post('inventory/inventory/qc_reject_inventory',rejectData,headerObj)
        .then( (response) => {
            this.setState({loading:false})
            if (response.data.status == 200) {
                this.logRejectedInventory();             
                toast.success(this.props.t('qualityControl.'+CONF_VAL.remove_success))                                            
            }   
            else{                
                toast.error(response.data.message);
            }             
        })
        .catch(function (response){
            this.setState({loading:false})
            toast.error(response);            
        }); 
    }

    resetState = () => {
        this.setState({
            loading:false,
            tagProfile:false,           
            reviewDone:false,
            profileCheck:{},
            approveButtonDisable:false,
            rejectInventoryCommentModal:false,
            picWithIssue : false,            
            qcData:{reg_no:""},
            rejectedImageIssue :[],
            excludeCarId :[],
            userLogState :[],
            carPrice:0,
            carDescription:'',
            carDescriptionInvalid:false,
            carPriceInvalid:false,
            regNoInvalid:false,
            dpInvalid: false,
            emiInvalid: false,
            emiMonthInvalid:false,
            nummberTypeInvalie: false,
            rejectReason: '',
            rejectComment: ''
            //totalQc:{needQc:0,redoQc:0},                
        },() => {
            this.setQcType()
        });        
    }

    render() {
        console.log("============this.state.carInventoryDetail======",this.state.carInventoryDetail);
        let regNoInvalid = this.state.regNoInvalid;
        const {inventoryId, make,model,modelVersion,price,year,kmDriven,color,created_date, transmission,engine_capacity,car_body_type,fuel_type,drive_type,dealer_name,bro_name,owner_name, ispremium, qc_status_comment,certification_status,qc_status} = this.state.carInventoryDetail;
        let tenureLists = this.state.tenureList;
        let disableField = false;
        if (certification_status == '1' && (qc_status == '2' || qc_status == '3')) {
            disableField = true;
        }
        return (            
            <div>
                <div className={this.state.loading?"container-fluid loading":"container-fluid" } >
                    <div className="row">
                        <div className="col-sm-8"><h1>{this.props.t('qualityControl.quality_control')} ({this.props.t('qualityControl.'+CONF_VAL[this.props.match.params.type||'dealer'])})</h1></div>
                        <div className="col-sm-4">
                        <div className="form-group">
                            <form  id="searchform"  onKeyDown={(e) => this.onKeyPressedEnter(e)} onSubmit={this.onSubmitSearchInventory} method="post" >
                            <div className="row">
                                {/* {CONF_VAL.type=='carmudi' &&
                                <div className="col-sm-4">
                                    <div>                                                                       
                                        <Select
                                            id="search_type"
                                            value={this.state.searchtype}
                                            onChange={this.handleChange.bind(this, 'searchtype')}
                                            options={searchtype}
                                            name="search_type"
                                            //placeholder="Search By"
                                            value={searchtype.filter(({ value }) => value === this.state.searchtype)}
                                            getOptionLabel={({ label }) => label}
                                            getOptionValue={({ value }) => value}
                                        />
                                    </div>
                                </div>
                                } */}
                                <div className={CONF_VAL.type=='carmudi'? "col-sm-8" :"col-sm-12"} >
                                <div className="input-group">
                                    <input type="search" name="searchbyid" id="searchbyid" placeholder="Search by ID" className="form-control" value={this.state.searchbyid} onChange={this.qcSearchCar} />
                                    <div className="input-group-append"><span className="btn btn-primary" onClick={()=>this.qcSearchInventory()}><i className="ic-search"></i></span></div>
                                </div>
                                </div>
                            </div>
                            </form>
                            </div>
                            
                        </div>
                    </div>                   
                    
                    <div className="card">
                        <div className="navbar">
                            <ul className="list-inline nav-tabs">                               
                                <li data-key="need_qc" className= {(this.state.activeQcType.type=='') ||this.state.activeQcType.type=='need_qc' && (!this.state.activeQcType.search) ? "nav-item active": "nav-item " }   onClick={() => this.handleTabClick("need_qc")}>
                                    <div>{this.props.t('qualityControl.'+CONF_VAL.need_qc)}<span>({this.state.totalQc.needQc})</span></div>
                                </li>
                                <li data-key="redo_qc" className= {this.state.activeQcType.type =='redo_qc' && (!this.state.activeQcType.search)? "nav-item active": "nav-item " } onClick={()=>this.handleTabClick("redo_qc")} >
                                    <div>{this.props.t('qualityControl.'+CONF_VAL.redo_qc)}<span>({this.state.totalQc.redoQc})</span></div>
                                </li>
                                <li data-key="pending_qc" className= {this.state.activeQcType.type =='pending_qc' && (!this.state.activeQcType.search)? "nav-item active": "nav-item " } onClick={()=>this.handleTabClick("pending_qc")} >
                                    <div>{this.props.t('qualityControl.pending_qc')}<span>({this.state.totalQc.pendingQC})</span></div>
                                </li>
                                <li data-key="search_qc" className={ (this.state.activeQcType.search)? "nav-item active ": "nav-item hide " }  >
                                    <div>({this.state.activeQcType.car_id}) <span className="closetab" onClick={()=>this.handleTabClick(this.state.activeQcType.type,false)}><i className="ic-clearclose"></i></span></div>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                           
                        {(!this.state.carInventoryDetail.inventoryId)? <h6 className="text-danger">no data found</h6> :
                       
                            <div className="row">
                                <div className="col-sm-7">
                                    <div className="row">
                                        <div className="col-sm-6"><span className="gray-text mrg-l10">{this.props.t('qualityControl.photos')}({this.state.carImages.length})</span> </div>
                                            {(this.state.carImages.length > 0) &&
                                                <div className="col-sm-6 text-right">

                                                    {this.state.largeClass == false ? <span className="gray-link  mrg-r15" onClick={this.showLargeImage}><i className="ic-fullscreen icons-normal"></i> {this.props.t('qualityControl.large_image')}
                                                    </span>
                                                        : <span className="gray-link  mrg-r15" onClick={this.showLargeImage}><i className="ic-fullscreen_exit icons-normal"></i> {this.props.t('qualityControl.small_image')}
                                                        </span>}
                                                    { !disableField ? <span className="gray-link mrg-r10" onClick={this.showModal} ><i className="ic-createmode_editedit "></i> {this.props.t('qualityControl.edit_all_image')}</span>: ''}
                                                </div>
                                            }
                                    </div>
                                    {(this.state.carImages.length>0 ) && 
                                    <div  className= {this.state.largeClass == false ? 'qcphoto ' : "qcphoto largeimg" }>
                                        <ul>
                                        {this.state.carImages.map((imgData, index) =>                                             
                                            <li key={index} className={this.state.inventoryIssueList[imgData.id] 
                                                && this.state.inventoryIssueList[imgData.id].length ? "imgissue":
                                             imgData.is_profile && imgData.is_profile == 1 ? "profileimg": ''}>
                                                <div className="imgwrap">
                                                    <img src={`${API_URL.GETWAY_API}qc/user/edit-image?url=${encodeURIComponent(imgData.url)}`} alt={index} ></img>
                                                    {
                                                        !disableField ?
                                                            <span className={!this.state.showmore[index] ? 'more' : "more"} onClick={(event) => this.showmoreAction(event, index)}>
                                                                <i className={!this.state.showmore[index] ? 'ic-more_vert ' : "ic-clearclose"} ></i>
                                                            </span> : ''
                                                }

                                                    <div className= {!this.state.showmore[index] ? 'moreaction ' : "moreaction open" }>
                                                        <ul>
                                                            {imgData.is_profile==1 ?
                                                            <li><div className="issue" onClick={() => this.unsetProfilePic(index)} >{this.props.t('qualityControl.'+CONF_VAL.unmark_profile)}</div></li>
                                                            :<li><div className="issue" onClick={() => this.setProfilePic(index)} >{this.props.t('qualityControl.'+CONF_VAL.mark_profile)}</div></li>
                                                            }
                                                            <li><div onClick={()=>this.openGalleryImage(index)} className="issue">{this.props.t('qualityControl.edit_image')}</div></li>
                                                            <li><div className="issue">{this.props.t('qualityControl.mark_issue')}</div>
                                                                <ul>
                                                                {this.state.issueTypeList.map((issueType) =>
                                                                    <li key={`action_${index}_${issueType.id}`}>
                                                                        <InputField
                                                                            inputProps={{
                                                                                id: imgData.id+'_'+issueType.id,
                                                                                type: "checkbox",
                                                                                name: issueType.issue_name,
                                                                                value: issueType.qc_point_id,
                                                                                label:issueType.issue_label,
                                                                                checked : (this.state.inventoryIssueList[imgData.id] && this.state.inventoryIssueList[imgData.id].indexOf(issueType.id) > -1) ? true: '' , 
                                                                                //onChange:{}                                    
                                                                            }}
                                                                            onChange={(event) => this.setCarImageIssue(event,issueType.id,{...imgData},this.state.carInventoryDetail.inventoryId)}                                      
                                                                        />
                                                                    </li>
                                                                )}
                                                                </ul>
                                                            </li>
                                                        </ul>                                                        
                                                    </div>                                                    
                                                </div>
                                            </li>
                                        )}
                                            
                                        </ul>
                                    </div>
                                    }
                                </div>
                                <div className="col-sm-5">
                                    <h4>{this.state.carInventoryDetail.car_title ? this.state.carInventoryDetail.car_title : this.state.carInventoryDetail.mmv} ({this.state.carInventoryDetail.inventoryId})</h4>
                                    <div className="row mrg-b15">
                                        <div className="col-sm-6">
                                            <span className="text-light">
                                                {(this.state.carInventoryDetail.dealerId && this.state.activeQcType.qc_for == 'dealer') ?
                                                    "GCD" + this.state.carInventoryDetail.dealerId : ''}
                                            </span>&nbsp;&nbsp;
                                            <button type="button" className="btn btn-link" onClick={() => this.showSellerDeatil()} >{this.props.t('qualityControl.seller_detail')}</button>

                                        </div>
                                     
                                        <div className="col-sm-6">
                                        
                                            {this.state.carInventoryDetail.webUrl.length ==0 ? '':
                                            <React.Fragment>
                                            <span className="text-light">{this.props.t('qualityControl.preview_on')} </span>
                                            {Array.isArray(this.state.carInventoryDetail.webUrl)?
                                               <>
                                                {this.state.carInventoryDetail.webUrl.map((data, index) =>
                                                    <a href={data.url} target="blank" key={index} title={data.label} className="sitepreview"><span className={"source-icon "+data.css_class}></span></a>
                                                )} 
                                                </> 
                                              :
                                              <>
                                                <a href={this.state.carInventoryDetail.webUrl} target="blank"  className="sitepreview"><span className={"source-icon zigwheel"}></span></a>
                                              </>
                                            }
                                            </React.Fragment>
                                            }                                        
                                        </div>                                       

                                    </div>
                                    <ul className="specs-list mrg-b15">
                                        {/* {make &&
                                        <li>
                                            <div className="label-dtl">{make} </div>
                                            <div className="label-head">{this.props.t('carspecs.make')} </div>
                                        </li>
                                        }
                                        {model &&
                                        <li>
                                            <div className="label-dtl">{model} </div>
                                            <div className="label-head">{this.props.t('carspecs.model')} </div>
                                        </li>
                                        }
                                        {modelVersion &&
                                        <li>
                                            <div className="label-dtl">{modelVersion} </div>
                                            <div className="label-head">{this.props.t('carspecs.modelVersion')} </div>
                                        </li>
                                        } */}
                                        {price &&
                                        <li>
                                            <div className="label-dtl">{price} </div>
                                            <div className="label-head">{this.props.t('carspecs.price')} </div>
                                        </li>
                                        }
                                        {year && 
                                        <li>
                                            <div className="label-dtl">{year}</div>
                                            <div className="label-head">{this.props.t('carspecs.year')}</div>
                                        </li>
                                        }
                                        {color && 
                                        <li>
                                            <div className="label-dtl">{color}</div>
                                            <div className="label-head">{this.props.t('carspecs.color')}</div>
                                        </li>
                                        }
                                        {kmDriven && 
                                        <li>
                                            <div className="label-dtl">{kmDriven}</div>
                                            <div className="label-head">{this.props.t('carspecs.kms')}</div>
                                        </li>
                                        }
                                        {created_date && 
                                        <li>
                                            <div className="label-dtl">{dateFormat(created_date, 'dd mmm, yyyy')}</div>
                                            <div className="label-head">{this.props.t('carspecs.created_date')}</div>
                                        </li>                                        
                                        }
                                        {transmission && 
                                        <li>
                                            <div className="label-dtl">{transmission}</div>
                                            <div className="label-head">{this.props.t('carspecs.transmission')}</div>
                                        </li>                                        
                                        }
                                        {car_body_type && 
                                        <li>
                                            <div className="label-dtl">{car_body_type}</div>
                                            <div className="label-head">{this.props.t('carspecs.body_type')}</div>
                                        </li>                                        
                                        }
                                        {engine_capacity && 
                                        <li>
                                            <div className="label-dtl">{engine_capacity}</div>
                                            <div className="label-head">{this.props.t('carspecs.engine_capacity')}</div>
                                        </li>                                        
                                        }
                                        {fuel_type && 
                                        <li>
                                            <div className="label-dtl">{fuel_type}</div>
                                            <div className="label-head">{this.props.t('carspecs.fuel_type')}</div>
                                        </li>                                        
                                        }
                                        {drive_type && 
                                        <li>
                                            <div className="label-dtl">{drive_type}</div>
                                            <div className="label-head">{this.props.t('carspecs.drive_type')}</div>
                                        </li>                                        
                                        }
                                        {dealer_name && this.state.activeQcType.qc_for == 'dealer' && 
                                        <li>
                                            <div className="label-dtl">{dealer_name}</div>
                                            <div className="label-head">{this.props.t('carspecs.dealer_name')}</div>
                                        </li>
                                        }
                                        {bro_name  && this.state.activeQcType.qc_for == 'dealer' &&   CONF_VAL.type=='oto' &&
                                        <li>
                                            <div className="label-dtl">{bro_name}</div>
                                            <div className="label-head">{this.props.t('carspecs.bro_name')}</div>
                                        </li>
                                        }
                                        {owner_name && this.state.activeQcType.qc_for == 'individual' && 
                                        <li>
                                            <div className="label-dtl">{owner_name}</div>
                                            <div className="label-head">{this.props.t('carspecs.owner_name')}</div>
                                        </li>
                                        }
                                   </ul>
                                   {this.state.activeQcType.qc_for == 'individual' && 
                                   <div className="rc-inline">
                                        <CheckBox
                                            type="checkbox" 
                                            name="mark_featured" 
                                            id="mark_featured" 
                                            value="1" 
                                            label={this.props.t('qualityControl.mark_featured')}  
                                            data-key="1" 
                                            key="1" 
                                            checked={(ispremium == '1') ? true: ''}
                                            onChange={this.handleFeaturedCheckbox}
                                        />
                                    </div>  
                                   }          
                                    <div className="card-border">                                     
                                                
                                                <h6>{this.props.t('qualityControl.'+CONF_VAL.profile_tag)} 
                                                 {this.state.tagProfile==true || (this.state.profileCheck['type'] =='profile_correct') ? 
                                                 <span className="label green-status mrg-l5"> {this.props.t('qualityControl.done')}</span> :
                                                  <span className="label red-status mrg-l5"> {this.props.t('qualityControl.notDone')}</span> 
                                                    }
                                                 </h6>
                                                <div className="rc-inline">
                                                <CheckBox
                                                    type="checkbox" 
                                                    name="profile_not_found" 
                                                    id="profile_not_found" 
                                                    value="1" 
                                                    label={this.props.t('qualityControl.'+CONF_VAL.profile_not_found)}  
                                                    data-key="1" 
                                                    key="1"
                                                    disabled={(this.state.tagProfile || disableField)? true:false} 
                                                    checked={(this.state.profileCheck['type']=='profile_not_found') ? true: ''}
                                                    onChange={this.handleProfileCheckbox}
                                                />
                                                    
                                                <CheckBox
                                                    type="checkbox" 
                                                    name="profile_correct" 
                                                    id="profile_correct" 
                                                    value="1" 
                                                    label={this.props.t('qualityControl.'+CONF_VAL.profile_correct)} 
                                                    data-key="2" 
                                                    key="2" 
                                                    disabled={(this.state.tagProfile || disableField)? true:false} 
                                                    checked={(this.state.profileCheck['type']=='profile_correct') ? true: ''}
                                                    onChange={this.handleProfileCheckbox}
                                                />
                                                </div>
                                                <hr />
                                                
                                                <h6>{this.props.t('qualityControl.'+CONF_VAL.fill_reg_no)}
                                                {this.state.qcData.reg_no !='' && !(regNoInvalid)
                                                  ? 
                                                    <span className="label green-status mrg-l5">{this.props.t('qualityControl.done')}</span> :
                                                    <span className="label red-status mrg-l5">{this.props.t('qualityControl.'+CONF_VAL.reg_no_invalid)}</span> 
                                                }
                                                </h6>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <InputField
                                                            inputProps={{
                                                                id: "reg_no",
                                                                type: "text",
                                                                placeholder: this.props.t('qualityControl.'+CONF_VAL.enter_reg_no),
                                                                name: "reg_no",
                                                                autocompleate: "off",
                                                                label: this.props.t('qualityControl.'+CONF_VAL.enter_reg_no),
                                                                value: this.state.qcData.reg_no,      
                                                                
                                                                disabled: (this.state.qcData.regnotavailable || disableField) ?true:false 
                                                            }}
                                                            onChange={this.handleChangetext}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label></label>
                                                        <CheckBox
                                                            type="checkbox" 
                                                            name="regnotavailable" 
                                                            id="regnotavailable" 
                                                            value="" 
                                                            label={this.props.t('qualityControl.not_available')} 
                                                            data-key="3" 
                                                            key="3"
                                                            checked={(this.state.qcData.regnotavailable) ?true:false}
                                                            disabled={disableField ? true:false}
                                                            onChange={this.handleChangetext}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <h6>{this.props.t('qualityControl.number_type')}</h6>
                                                        <div className="rc-inline">
                                                            <RadioBox disabled={disableField ? true : false} type="radio" name="number_type" id="number_type_1" value="ODD" label={this.props.t('qualityControl.odd')} checked={(this.state.number_type == 'ODD') ?true:false} onChange ={(event) =>this.numberTypeSelect(event)} />
                                                            <RadioBox disabled={disableField ? true : false} type="radio" name="number_type" id="number_type_2" value="EVEN" label={this.props.t('qualityControl.even')} checked={(this.state.number_type == 'EVEN') ?true:false} onChange ={(event) =>this.numberTypeSelect(event)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <h6>{this.props.t('qualityControl.car_price')}
                                                            {this.state.carPriceInvalid==true ?
                                                            <span className="label red-status mrg-b15 mrg-l5">{this.props.t('qualityControl.car_price_error')+this.state.numberPlatePriceValidation.min_car_price}</span> :
                                                            <span className="label green-status mrg-b15 mrg-l5"> {this.props.t('qualityControl.done')}</span> 
                                                            }
                                                        </h6>    
                                                        <InputField
                                                            inputProps={{
                                                                id: "car_price",
                                                                type: "text",
                                                                placeholder: this.props.t('carspecs.price'),
                                                                name: "carPrice",
                                                                autocompleate: "off",
                                                                label:this.props.t('carspecs.price'),
                                                                label_before_input:0,
                                                                value: this.state.carPrice,     
                                                            }}
                                                            onChange={this.handlePriceChange}
                                                        />
                                                    </div>
                                                    

                                                    <div className="col-sm-6">
                                                        <h6>{this.props.t('qualityControl.downpayemnt')}
                                                            {/* {this.state.downpayemnt==true ?
                                                            <span className="label red-status mrg-b15 mrg-l5">{this.props.t('qualityControl.car_price_error')+this.state.numberPlatePriceValidation.min_car_price}</span> :
                                                            <span className="label green-status mrg-b15 mrg-l5"> {this.props.t('qualityControl.done')}</span> 
                                                            } */}
                                                        </h6>    
                                                        <InputField
                                                            inputProps={{
                                                                id: "down_payment",
                                                                type: "text",
                                                                placeholder: this.props.t('carspecs.dp'),
                                                                name: "down_payment",
                                                                autocompleate: "off",
                                                                label:this.props.t('carspecs.dp'),
                                                                label_before_input:0,
                                                                value: this.state.down_payment,
                                                                disabled: (disableField) ?true:false
                                                            }}
                                                            onChange={this.handleChangetext}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <h6>{this.props.t('qualityControl.emi')}
                                                            {/* {this.state.carPriceInvalid==true ?
                                                            <span className="label red-status mrg-b15 mrg-l5">{this.props.t('qualityControl.car_price_error')+this.state.numberPlatePriceValidation.min_car_price}</span> :
                                                            <span className="label green-status mrg-b15 mrg-l5"> {this.props.t('qualityControl.done')}</span> 
                                                            } */}
                                                        </h6>    
                                                        <InputField
                                                            inputProps={{
                                                                id: "emi",
                                                                type: "text",
                                                                placeholder: this.props.t('carspecs.emi'),
                                                                name: "emi",
                                                                autocompleate: "off",
                                                                // label:this.props.t('qualityControl.emi'),
                                                                label_before_input:0,
                                                                value: this.state.emi,
                                                                disabled: (disableField) ?true:false
                                                            }}
                                                            onChange={this.handleChangetext}
                                                        />
                                                    </div>
                                                <div className="col-sm-6">
                                                    <h6>{this.props.t('qualityControl.emi_month')}
                                                        {/* {this.state.carPriceInvalid == true ?
                                                            <span className="label red-status mrg-b15 mrg-l5">{this.props.t('qualityControl.car_price_error') + this.state.numberPlatePriceValidation.min_car_price}</span> :
                                                            <span className="label green-status mrg-b15 mrg-l5"> {this.props.t('qualityControl.done')}</span>
                                                        } */}
                                                    </h6>

                                                    <Select
                                                        id="emi_month"
                                                        value={this.state.emi_month}
                                                        onChange={this.handleChange.bind(this, 'emi_month')}
                                                        options={tenureLists}
                                                        name="emi_month"
                                                        label={this.props.t('qualityControl.emi')}
                                                        //placeholder="Search By"
                                                        value={tenureLists.filter(({ value }) => value === this.state.emi_month)}
                                                        getOptionLabel={({ value }) => value}
                                                        getOptionValue={({ key }) => key}
                                                        isDisabled={disableField}
                                                    />

                                                </div>


                                                    <div className="col-sm-12">
                                                        <label className="mrg-b5">{this.props.t('carspecs.description')}
                                                            {this.state.carDescriptionInvalid==true ?
                                                            <span className="label red-status mrg-b15 mrg-l5">{this.props.t('qualityControl.car_description_error')}</span> :
                                                            <span className="label green-status mrg-b15 mrg-l5"> {this.props.t('qualityControl.done')}</span> 
                                                            } 
                                                        </label>
                                                        {/* <textarea className="form-control" name="carDescription" id="car_description" 
                                                        onChange={(event)=>this.handleDescriptionChange(event)} 
                                                        value={this.state.carDescription|| ''}>
                                                        </textarea> */}
                                                        <br /><br />
                                                        <Editor                                                            
                                                            key={inventoryId}
                                                            apiKey="9gh0yn6tndfh684vhu2ibdccw57q3n2pks1mxrmqyvqzmhaa"
                                                            initialValue={this.state.carDescription}
                                                            init={{
                                                            height: 200,
                                                            menubar: false,
                                                            branding: false,
                                                            init_instance_callback: function (editor) {
                                                                document.querySelector('button.tox-statusbar__wordcount')?.click();
                                                                document.querySelector('.tox .tox-editor-header').style.zIndex = '0';
                                                               // editor.getContainer().find('button.tox-statusbar__wordcount').click();  // if you use jQuery
                                                            },
                                                                // paste_preprocess: function (plugin, args) {
                                                                //     args.content = '';
                                                                // },
                                                            plugins: [
                                                                'advlist autolink lists link charmap print preview anchor',
                                                                'searchreplace visualblocks code fullscreen',
                                                                'insertdatetime table paste code help wordcount'
                                                            ],
                                                            toolbar:
                                                                'undo redo | formatselect | bold italic underline | \
                                                                alignleft aligncenter alignright alignjustify | \
                                                                bullist numlist outdent indent | removeformat'
                                                            }}
                                                            onEditorChange={this.handleEditorChange}
                                                        />

                                                    </div>
                                                </div>
                                                {/* <div className="form-group"><Button btnClass="btn btn-default" title={this.props.t('qualityControl.submit_reg')} type="submit"/></div> */}
                                                <hr />
                                                
                                               
                                                
                                                <h6>
                                                    {this.props.t('qualityControl.review_all_pictures')}                                                     
                                                    {this.state.picWithIssue == false ? '' :
                                                        <span className="label red-status mrg-l5">{this.props.t('qualityControl.pic_with_issue')}</span>
                                                     }
                                                     {this.state.reviewDone == true ? 
                                                        <span className="label green-status mrg-l5">{this.props.t('qualityControl.done')}</span> :''
                                                    }
                                                </h6>
                                                <div className="rc-inline">
                                                        <CheckBox
                                                            type="checkbox" 
                                                            name="reviewdone" 
                                                            id="reviewdone" 
                                                            value="" 
                                                            label={this.props.t('qualityControl.review_done')} 
                                                            data-key="4" 
                                                            key="4"
                                                            checked={(this.state.reviewDone)? true:''}
                                                            onChange ={(event) =>this.reviewDone(event)}
                                                            disabled={this.state.picWithIssue? true:false}
                                                        />                                                        
                                                        <CheckBox
                                                        type="checkbox"
                                                        name="reject_photo"
                                                        id="reject_photo"
                                                        value=""
                                                        label={this.props.t('qualityControl.reject_photo')}
                                                        data-key="5"
                                                        key="5"
                                                        disabled={disableField ? true : false}
                                                        checked ={this.state.picWithIssue ? true : ''}
                                                        onChange={this.showreject}
                                                        />          
                                                </div>
                                                <hr />
                                                <div className="form-group">
                                                        <Button btnClass="btn btn-primary mrg-r15" title={this.props.t('qualityControl.'+CONF_VAL.approve)} 
                                                        disabled={(this.state.approveButtonDisable)? true:false}    onClick={ () => this.handleApproveInventory()} type="submit"/>
                                                    
                                                        <Button btnClass="btn btn-default mrg-r15" disabled={(this.state.approveButtonDisable)? true:false} title= {this.props.t('qualityControl.'+CONF_VAL.remove)} onClick={ () =>this.confirmDeleteInventory()} type="submit"/>
                                                    
                                                        {(this.state.activeQcType.type == 'pending_qc' || (qc_status_comment && qc_status_comment.indexOf('QC_PENDING') > -1)) ? 
                                                            <Button btnClass="btn btn-default" disabled={(this.state.approveButtonDisable)? true:false} title= {this.props.t('qualityControl.'+CONF_VAL.reject_by_price)} onClick={ () =>this.takeRejectComment()} type="submit"/>
                                                            : ''
                                                        }
                                                    </div>
                                                {this.state.activeQcType.search==true && this.state.carApprovalStates.length> 0 ? 
                                                <React.Fragment>                                                
                                                    {this.state.carApprovalStates.map((carApproval, index) => 
                                                    <p key={index} className="text-light">{this.props.t('qualityControl.approve_by')} {carApproval.user_name+ " at "+carApproval.created_date} </p>
                                                    )}
                                                </React.Fragment>
                                                    :                                                    
                                                     <p className="text-light">{/*this.props.t('qualityControl.approve_by')*/}  {/*Divyanshi at 2019-10-25 17:40*/}</p>
                                                }
                                            </div>                                            
                                        </div>
                            </div>
                        }        
                        </div>
                    </div>
    
                </div>
                {/* <Carousel slides={this.state.carouselSlidesData}  ></Carousel> */}
                {this.state.showgallerymodal == false ? '' : <ModalPopup id="editgallary" className="modal modal-lg" title={this.props.t('qualityControl.edit_photos')} modalClose={this.closeModal}>
                    {/* <ButtonGroup className="asd" /> */}                  
                    
                    <GallaryCarousel slides={this.state.carImages} updateCarImages={this.updateQCarImages} galleryImageId ={this.state.editImageId}></GallaryCarousel>
                    {/* <TagPhotos /> */}
                </ModalPopup>
                }
                {this.state.showconfirmmodal == false ? '' : <ModalPopup id="confirmDelete" className="modal modal-sm" title={this.props.t('qualityControl.remove_inventoty')} modalClose={this.closeModal}>
                    <p>{this.props.t('qualityControl.confirm_delete')}</p>
                    <div className="form-group">
                        <label className="mrg-b5">{this.props.t('qualityControl.remove_reason')}</label>                        
                                <Select
                                            id="reject_reason"
                                            //value={this.state.searchtype}
                                            onChange={this.handleChange.bind(this, 'rejectReason')}
                                            options={CONF_VAL.reject_reason}
                                            name="rejectReason"
                                            //placeholder="Search By"
                                            //value={searchtype.filter(({ value }) => value === this.state.searchtype)}
                                            getOptionLabel={({ label }) => label}
                                            getOptionValue={({ value }) => value}
                                        />
                        </div>                
                            <div className="form-group">
                                <label className="mrg-b5">{this.props.t('qualityControl.comment')}</label>
                                <textarea className="form-control" name="rejectComment" id="reject_comment" 
                                    onChange={(event)=>this.handleRejectCommentChange(event)} 
                                    value={this.state.rejectComment}>
                                </textarea>
                            </div>
                    <div className="text-right">                        
                        <Button btnClass="btn btn-default mrg-r15" title={this.props.t('qualityControl.cancel')} onClick={this.closeModal} type="submit"/>
                        <Button btnClass="btn btn-primary " title={this.props.t('qualityControl.confirm')} onClick={()=>this.handleDeleteInventory()} type="submit"/>
                    </div>

                </ModalPopup>
                }

                {this.state.showSellerDeatil == false ? '' :
                    <ModalPopup id="SellerInformation" className="modal" title={this.props.t('qualityControl.seller_information')} modalClose={this.closeModal}>
                      <SellerInformation car_hash = {this.state.carInventoryDetail.carHash} car_id = {this.state.carInventoryDetail.inventoryId}></SellerInformation>
                    </ModalPopup>
                }
                {this.state.showrejectreason == false ? '' : <ModalPopup id="markissue" className="modal" title={this.props.t('qualityControl.mark_issue')} modalClose={this.closeModal}>
                    
                <RejectReason handleRejectAllImages={this.handleRejectAllImages} reasonArr={this.state.issueTypeList} rejectedImageIssue={this.state.rejectedImageIssue} />
                    <div className="form-group">
                        <Button btnClass="btn btn-primary" title={this.props.t('qualityControl.save')} onClick={this.saveAllReject} type="submit"/>
                        {/* <Button btnClass="btn btn-default mrg-r15" title={this.props.t('qualityControl.approve')} onClick={this.closeModal} type="submit"/> */}
                    </div>
                </ModalPopup>
                }

                {this.state.rejectInventoryCommentModal == false ? '' : 
                    <ModalPopup id="rejectComment" className="modal" title={this.props.t('qualityControl.reject_inventory')} modalClose={this.closeModal}>                        
                        <div>
                        <div className="form-group">
                        <label className="mrg-b5">{this.props.t('qualityControl.reject_reason')}</label>                        
                                <Select
                                            id="reject_reason"
                                            //value={this.state.searchtype}
                                            onChange={this.handleChange.bind(this, 'rejectReason')}
                                            options={CONF_VAL.reject_reason}
                                            name="rejectReason"
                                            //placeholder="Search By"
                                            //value={searchtype.filter(({ value }) => value === this.state.searchtype)}
                                            getOptionLabel={({ label }) => label}
                                            getOptionValue={({ value }) => value}
                                        />
                        </div>                
                            <div className="form-group">
                                <label className="mrg-b5">{this.props.t('qualityControl.comment')}</label>
                                <textarea className="form-control" name="rejectComment" id="reject_comment" 
                                    onChange={(event)=>this.handleRejectCommentChange(event)} 
                                    value={this.state.rejectComment}>
                                </textarea>
                            </div>
                            <div className="text-right">                        
                                <Button btnClass="btn btn-default mrg-r15" title={this.props.t('qualityControl.cancel')} onClick={this.closeModal} />
                                <Button btnClass="btn btn-primary " title={this.props.t('qualityControl.'+CONF_VAL.reject_by_price)} onClick={()=>this.rejectInventory()} />
                            </div>                            
                        </div>                                                                    
                    </ModalPopup>
                }    

            </div>
        
        );
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        //dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('qc')(connect(mapStateToProps, mapDispatchToProps)(QualityControl));


    //export default QualityControl;carImages