const findMatch=  (str, arr) => {
    //Desc
    //let str = 'https://otousedcar.storage.googleapis.com/TH/car_image/stg/22024/1710683624832.jpg';
    //let arr = ['https://otousedcar.storage.googleapis.com/', 'https://storage.googleapis.com/otousedcar/'];
    //will return "https://otousedcar.storage.googleapis.com/"
    for (const element of arr) {
        if (str.startsWith(element)) {
          return element;
        }
      }
      return null; // Return null if no match is found
}

export {findMatch};